"use client"
import { Layouts } from "@/components/layouts"
import { ModeToggle } from "@/components/themes/mode-toggle"

export default function Layout({children}: {children: React.ReactNode}) {
    return <>
        <Layouts.Full>
        <div className='flex justify-end items-center h-[100px] p-10 gap-3'>
          <ModeToggle />
        </div>
        <div className='flex justify-center items-center h-[calc(100vh-220px)] min-h-[400px]'>
          <div className='w-full max-w-[550px] px-5'>
            {children}
          </div>
        </div>
      </Layouts.Full>
    </>
}